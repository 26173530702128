<template>
  <view-container v-if="competition" name="details">
    <view-header>
      <custom-header class="view-header" :router-back="$goBack">
        <template v-slot:default>
          <svg-icon size="size24x24" name="icoArrowLeft" />
          {{ competition.name }}
        </template>
      </custom-header>
    </view-header>

    <view-content v-if="results && competition">
      <a-tabs ref='tabs' type="card" :tab-bar-gutter="8" class="tab">
        <a-tab-pane key="opened">
          <span slot="tab"> {{ $t('results.sortByHeat') }} </span>
          <a-collapse>
            <a-collapse-panel v-for="heat, ind in sortByTrack" :key="`${heat.heat}${ind}`" :header="heat.heat">

              <div v-if="heat.video">
                <video class="video" :src="heat.video.url" controls></video>
              </div>

              <!-- <div class="details"> НЕ видаляти!
                <button class="verdict-component__share" style="color: #fff;">
                  <Icon :size="20" type="share"/>

                  <span> {{ $t('competition.actions.share') }} </span>
                </button>
              </div> -->
              <div v-for="participant, index in heat.places" :key="`participant${ind}_${index}`"
                class="competitor-component drop-item">
                <div class="drop-item__info w-100">
                  <span class="me-1 align-self-start"># {{
                    participant.track_number }}</span>
                  <a-avatar :src="participant.user.photo" :size="40" icon='user'>
                  </a-avatar>
                  <div class="user">
                    <div class="name" style="margin-bottom: 4px;">
                      {{ isCurrUser(participant.user) ? 'You' : participant.user.name }}
                    </div>
                    <div class="flag">
                      <img :src="participant.user.flag" alt="country">
                    </div>
                  </div>
                  <div v-if="!participant.is_disqualification" class="info d-block" style="width: 80px;">
                    <div v-if="!participant.is_disqualification" class="d-flex align-items-center"
                      style="margin-bottom: 5px;">
                      <span class="d-inline-block" style="width:15px">{{ getPlaceByParticipant(participant) }}</span>
                      <img v-if="getPlaceByParticipant(participant) === 1" src="@/assets/img/gold-medal.svg" alt="gold">
                      <img v-else-if="getPlaceByParticipant(participant) === 2" src="@/assets/img/silver-medal.svg"
                        alt="gold">
                      <img v-else-if="getPlaceByParticipant(participant) === 3" src="@/assets/img/bronze-medal.svg"
                        alt="gold">
                    </div>
                    <div style="margin-bottom: 4px;">{{ participant.race_time }}</div>
                  </div>
                  <div v-else class='info dsq'>
                    {{ $t('pages.verdict.dsq') }}
                  </div>
                </div>
              </div>
              <template v-if='disqualification(ind).length'>
                <h2 class='disqualified-title'>Official notes</h2>
                <div v-for="participant, index in disqualification(ind)" :key="`participant_dsq${ind}_${index}`"
                  class="competitor-component drop-item d-block">
                  <div class="drop-item__info w-100">
                    <a-avatar :src="participant.user.photo" :size="40" icon='user'>
                    </a-avatar>
                    <div class="user">
                      <div class="name" style="margin-bottom: 4px;">
                        {{ participant.user.name }}
                      </div>
                      <div class="flag">
                        <img :src="participant.user.flag" alt="country">
                      </div>
                    </div>
                    <div class='info dsq' style='width: auto'>was disqualified</div>
                  </div>
                  <p v-if='participant.notes' class='dsq-text'>
                    {{ participant.notes }}
                  </p>
                </div>
              </template>

            </a-collapse-panel>
          </a-collapse>

        </a-tab-pane>

        <a-tab-pane key="live">
          <div slot="tab">
            <span> {{ $t('results.sortByPlace') }} </span>
          </div>
          <div class="pane">
            <div v-for="participant, index in sortByPlace" :key="`user${index}`" class="competitor-component drop-item">
              <div class="drop-item__info w-100">
                <div class="info d-block" style="width:80px">
                  <div v-if="!participant.is_disqualification" class="d-flex align-items-center"
                    style="margin-bottom: 5px;">
                    <span class="d-inline-block" style="width:15px">{{ index + 1 }}</span>
                    <img v-if="index === 0" src="@/assets/img/gold-medal.svg" alt="gold">
                    <img v-else-if="index === 1" src="@/assets/img/silver-medal.svg" alt="gold">
                    <img v-else-if="index === 2" src="@/assets/img/bronze-medal.svg" alt="gold">
                  </div>
                  <div v-if="!participant.is_disqualification">{{ participant.race_time }}</div>
                  <div v-else class='info dsq'>
                    {{ $t('pages.verdict.dsq') }}
                  </div>
                </div>
                <a-avatar :src="participant.user.photo" :size="40" icon='user'>
                </a-avatar>
                <div class="user">
                  <div class="name" style="margin-bottom: 4px;">
                    {{ isCurrUser(participant.user) ? 'You' : participant.user.name }}
                  </div>
                  <div class="flag">
                    <img :src="participant.user.flag" alt="country">
                  </div>
                </div>
                <div class="me-1 align-self-start" style="width: 60px">
                  # {{ participant.track_number }}
                  <small>{{ participant.heat }}</small>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </view-content>

  </view-container>
</template>

<script>
import competition from '@/mixins/competition'
// import Icon from '@/components/Icon'
import { getTournamentsResults, getEventsResults, getTournamentsEventVerdict, getEventsVerdict } from '@/api/public/competition'

export default {
  name: 'StreamedEvent',
  mixins: [
    competition
  ],
  props: {
    competitionId: {
      type: [String, Number],
      required: true
    }
  },
  // components: {
  //     Icon,
  // },
  data() {
    return {
      competition: null,
      results: null
    }
  },
  computed: {
    disqualification: (vm) => {
      return (index) => {
        return vm.results?.heats[index].places.filter(user => user.is_disqualification)
      }
    },
    sortByPlace() {
      const disc = this.results.places.filter((part) => part.is_disqualification)
      console.log('PLACES', disc);
      const results = this.results.places.filter((part) => !part.is_disqualification)
      return [...results, ...disc]
    },
    sortByPlaceDisc() {
      return this.results.places.filter((part) => part.is_disqualification)
    },
    sortByTrack() {
      const heats = this.results.heats
      const sorted = []
      for (let heat of heats) {
        heat.places.sort((a, b) => (a.track_number > b.track_number) ? 1 : -1)
        sorted.push(heat)
      }
      return sorted
    }
  },
  mounted() {
    if (this.$route.name === 'streamed-event') this.initEvent();
    if (this.$route.name === 'streamed-tournament-event') this.initTournamentEvent();
  },
  methods: {
    async initEvent() {
      try {
        const { data } = await getEventsResults(this.competitionId)
        const { data: competition } = await getEventsVerdict(this.competitionId)
        this.results = data
        this.competition = competition
      } catch (e) {
        console.log(e)
      }
    },
    async initTournamentEvent() {
      try {
        const { data } = await getTournamentsResults(this.competitionId)
        const { data: competition } = await getTournamentsEventVerdict(this.competitionId)
        this.results = data
        this.competition = competition
      } catch (e) {
        console.log(e)
      }
    },
    isCurrUser(user) {
      console.log('AUTH USER', this.$store?.state?.auth?.user)
      console.log('USER', user)
      return user.name === this.$store?.state?.auth?.user?.name && user.country === this.$store?.state?.auth?.user.country.name && (user.photo === this.$store?.state?.auth?.user.photo || !!user.photo === !!this.$store?.state?.auth?.user.photo)
    },
    getPlaceByParticipant(part) {
      const user = this.sortByPlace.find(el => el.user.name === part.user.name && el.user.photo === part.user.photo)
      return this.sortByPlace.indexOf(user) + 1
    }
  }
}
</script>

<style scoped>
.video {
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  width: calc(100% - 24px);
  margin: 20px 12px 0;
  background: #141723;
}

.video .icon {
  fill: #d8d8d8;
  border: 1px solid #d8d8d8;
  border-radius: 50%;
  z-index: 1;
}

.details {
  height: 48px;
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  background-color: #1d2130;
  margin-bottom: 20px;
}

.tab>>>.ant-tabs-nav {
  display: block;
}

.tab>>>.ant-tabs-nav>div {
  display: flex;
}

.tab>>>.ant-tabs-tab {
  flex-grow: 1;
}

.user {
  padding-left: 20px;
  color: #fff;
  flex-grow: 1;
  font-size: 14px;
}

.info {
  width: 60px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  align-self: flex-start;
  color: #fff;
}

.info img {
  max-width: 17px;
  max-height: 17px;
}

.me-1 {
  font-size: 16px;
  font-weight: 700;
  min-width: 40px;
  color: #fff;
}

.me-1 small {
  display: block;
  font-size: 12px;
  padding-top: 5px;
  font-weight: 500;
}

.pane {
  background-color: #1d2130;
  border-radius: 0 0 4px 4px;
}

.pane .drop-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  padding: 12px;
}

.pane .drop-item:last-child {
  border-bottom: none;
}

.info.dsq {
  color: #ff3737;
  font-size: 10px;
  align-self: flex-end;
  line-height: 1;
}

.disqualified-title {
  color: #D8D8D8;
  font-size: 1.1rem;
  padding-top: 30px;
  border-top: 1px solid #272a35;
  margin-bottom: 15px;
  padding-left: 12px;
}

.dsq-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #7B8B97;
  margin: 0;
  margin-top: 10px;
}

.flag>img {
  width: 30px;
  height: 17px;
}
</style>